<template>
  <product-type-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import ProductTypeTable from '../components/product-type/ProductTypeTable.vue'
  export default {
    name: 'AdminProductType',
    components: {
      ProductTypeTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness']),
    },

    async created () {
      await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchProductType',
      ]),
    },
  }
</script>
