<template>
  <div>
    <v-tooltip
      v-if="productType.status === 1"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductType"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onDeactiveWarehouse"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para inhabilitar el tipo de producto</span>
    </v-tooltip>

    <v-tooltip
      v-else
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProductType"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="onActiveWarehouse"
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para habilitar el tipo de producto</span>
    </v-tooltip>

    <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
      @click="openedEditDialog = true"
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn>
    <edit-product-type-dialog
      v-model="openedEditDialog"
      :product-type="productType"
    />
  </div>
</template>

<script>
  import EditProductTypeDialog from './EditProductTypeDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'ProductTypeActions',
    components: {
      EditProductTypeDialog,
    },
    props: {
      productType: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProductType',
        'user',
        'actionsParentBusiness',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProductType',
        'deleteProductType',
        'activeProductType',
      ]),
      async onDeactiveWarehouse () {
        await this.deleteProductType({ id: this.productType.id })
        await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async onActiveWarehouse () {
        await this.activeProductType({ id: this.productType.id })
        await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
