<template>
  <v-dialog
    v-model="openedDialog"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        Crear tipo de productos
      </v-card-title>
      <add-product-type-form @close="openedDialog = false" />
    </v-card>
  </v-dialog>
</template>

<script>
  import AddProductTypeForm from './AddProductTypeForm.vue'

  export default {
    name: 'AddProductType',
    components: { AddProductTypeForm },
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      openedDialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
